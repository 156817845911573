export const PROCESSED_URL_PATH = '/api/file/image';
export const VIDEO_PROCESSED_URL_PATH = '/api/file/video';
export const CONTENT_TYPES = {
  PNG: 'image/png',
  MP4: 'video/mp4',
};

export const INTRO_VIDEO_URL =
  'https://app-v2.booth.funstudio.vn/static/resources/Introduction_Booth.mp4';
export const THANKFUL_VIDEO_URL =
  'https://app-v2.booth.funstudio.vn/static/resources/Thank_you_Booth.mp4';
