import { useCallback, useMemo } from 'react';
import { PHOTO_PAGE } from 'constants/photo.const';
import { BE_DEFINES } from 'constants/backend-defines.const';
import { isEqualVal } from 'helpers/string.helper';
import { includesId } from 'helpers/array.helper';
import { isEqualPath } from 'helpers/url.helper';
import { find, findKey, size } from 'lodash';
import { LifeCycleStepModel } from 'models/app.model';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';

export const useNewPhotoLifeCycleStep = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { LifeCycleStep, isChoosePaymentMethod, isChooseQRCodeToPay } =
    useAppSelector((state) => state.selfeApp);
  const { boothInfo, isTurnOnPayment, isTurnOnMomoMethodPayment } =
    useAppSelector((s) => s.booth);

  // const LifeCycleStep: AppLifeCycleStepModel = useMemo(
  //   () => PRINTING_PHOTO_LIFE_CYCLE_STEP_DEFAULT,
  //   [],
  // );

  const getCurrentRouteByRouteId = useCallback(
    (routeId: string = '') => {
      if (routeId) {
        return LifeCycleStep?.[routeId];
      }
      return null;
    },
    [LifeCycleStep],
  );

  const getCurrentRouteByPathName = useCallback(
    (path: string = '') => {
      const routeId = findKey(LifeCycleStep, (o) =>
        isEqualPath(o?.route, path || pathname),
      );
      if (routeId) {
        return LifeCycleStep?.[routeId];
      }
      return null;
    },
    [LifeCycleStep, pathname],
  );

  const getStartNodePath = () =>
    find(LifeCycleStep, (o) => !!o?.isStartNode)?.route;

  const getNextPath = (
    path: string | null = '',
    skipRouteIds: string[] = [],
  ): string => {
    const _pathname = path || pathname;
    const currentRoute = getCurrentRouteByPathName(_pathname);
    const nextRouter = getCurrentRouteByRouteId(currentRoute?.nextId);

    // nếu route tiếp theo phải check điều kiện
    if (nextRouter?.isConditionRoute) {
      if (
        // nếu route tiếp theo trong skipRouteIds => skip luôn
        (!!size(skipRouteIds) && includesId(skipRouteIds, nextRouter?.id)) ||
        // Nếu đã chọn "Phương thức thanh toán", next là PaymentMethod => skip luôn
        (isChoosePaymentMethod &&
          isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT_METHOD)) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", next là QR code => skip luôn
        (!isTurnOnPayment &&
          (isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT) ||
            isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
            isEqualVal(nextRouter?.id, PHOTO_PAGE.QR_CODE))) ||
        // -----
        // Nếu tắt chức năng thành toán qua momo, chọn phương thức và trang qrcode momo => skip luôn
        (!isTurnOnMomoMethodPayment &&
          (isEqualVal(nextRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
            isEqualVal(nextRouter?.id, PHOTO_PAGE.QR_CODE))) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", next là QR code => skip luôn
        (isChoosePaymentMethod &&
          !isChooseQRCodeToPay &&
          isEqualVal(nextRouter?.id, PHOTO_PAGE.QR_CODE)) ||
        // -----
        // Nếu booth không phải Arm Robot Booth, next là route ShootingPosition => skip luôn
        (!isEqualVal(boothInfo?.Type, BE_DEFINES.ROBOT_ARM_BOOTH) &&
          isEqualVal(nextRouter?.id, PHOTO_PAGE.SHOOTING_POSITION))
      ) {
        return getNextPath(nextRouter?.route, skipRouteIds);
      }
    }

    return nextRouter?.route || '';
  };

  const getPrevPath = (
    path: string | null = '',
    skipRouteIds: string[] = [],
  ): string => {
    const _pathname = path || pathname;
    const currentRoute = getCurrentRouteByPathName(_pathname);
    const prevRouter = getCurrentRouteByRouteId(currentRoute?.prevId);

    // nếu route tiếp theo phải check điều kiện
    if (prevRouter?.isConditionRoute) {
      if (
        // nếu route tiếp theo trong skipRouteIds => skip luôn
        (!!size(skipRouteIds) && includesId(skipRouteIds, prevRouter?.id)) ||
        // Nếu đã chọn "Phương thức thanh toán", prev là PaymentMethod => skip luôn
        (isChoosePaymentMethod &&
          isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT_METHOD)) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", next là QR code => skip luôn
        (!isTurnOnPayment &&
          (isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT) ||
            isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
            isEqualVal(prevRouter?.id, PHOTO_PAGE.QR_CODE))) ||
        // -----
        // Nếu tắt chức năng thành toán qua momo, chọn phương thức và trang qrcode momo => skip luôn
        (!isTurnOnMomoMethodPayment &&
          (isEqualVal(prevRouter?.id, PHOTO_PAGE.PAYMENT_METHOD) ||
            isEqualVal(prevRouter?.id, PHOTO_PAGE.QR_CODE))) ||
        // -----
        // Nếu đã chọn phương thức thanh toán là "tiền mặt", prev là QR code => skip luôn
        (isChoosePaymentMethod &&
          !isChooseQRCodeToPay &&
          isEqualVal(prevRouter?.id, PHOTO_PAGE.QR_CODE)) ||
        // -----
        // Nếu booth không phải Arm Robot Booth, prev là route ShootingPosition => skip luôn
        (!isEqualVal(boothInfo?.Type, BE_DEFINES.ROBOT_ARM_BOOTH) &&
          isEqualVal(prevRouter?.id, PHOTO_PAGE.SHOOTING_POSITION))
      ) {
        return getPrevPath(prevRouter?.route);
      }
    }

    return prevRouter?.route || '';
  };

  const setLifeCyclePageById = useCallback(
    (id: string = '', data: Partial<LifeCycleStepModel> | any) => {
      if (id) {
        dispatch(
          setSelfeAppStateAction({
            LifeCycleStep: { ...LifeCycleStep, [id]: data },
          }),
        );
      }
    },
    [LifeCycleStep, dispatch],
  );

  const currentRoute = useMemo(
    () => getCurrentRouteByPathName(pathname),
    [getCurrentRouteByPathName, pathname],
  );

  return {
    getCurrentRouteByPathName,
    setLifeCyclePageById,
    getNextPath,
    getPrevPath,
    getStartNodePath,
    currentRoute,
  };
};
